<template>
  <div id="listIndex">
    <div class="choice-box">
      <div class="tabbar" @click="popShow = true">
        <img class="logo" v-lazy="shopInfo.market_logo" alt/>
        <img class="btn" src="@images/nav.png" alt/>
      </div>

      <div class="status-box">
        <div
          :class="activeIndex === index ? 'status-item active' : 'status-item'"
          v-for="(item, index) in choiceItem"
          :key="index"
          @click="changeIndex(index)"
        >{{item}}
        </div>
      </div>
    </div>
    <img class="nothing" src="@images/nothing.png" alt v-if="nothing"/>
    <p class="nothing-text" v-if="nothing">暂无相关订单</p>

    <div v-if="!nothing">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          class="list-box"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="getList"
        >
          <div
            class="list-item"
            v-for="(item, index) in orderList"
            :key="index"
            @click="goDetail(item.id)"
          >
            <img
              class="status-pic"
              src="@images/finish-status.png"
              alt
              v-if="item.order_status == 3"
            />
            <img
              class="status-pic"
              src="@images/cancel-status.png"
              alt
              v-if="item.order_status == 7"
            />
            <div class="top-info">
              <div class="top-inner">
                <span class="info-name">{{item.pay_type === "1"? item.contacts : "门店交易订单"}}</span>
                <span class="info-address" v-if='item.pay_type === "1"'>{{item.address}}</span>
              </div>
              <p v-if="item.order_status == 1">待配送</p>
              <p v-if="item.order_status == 2">配送中</p>
            </div>

            <div class="center-info">
              <swiper :options="swiperOption" class="myswiper">
                <swiper-slide v-for="(items) in item.product_order_info" :key="items.id">
                  <img v-lazy="items.product_cover" alt/>
                </swiper-slide>
              </swiper>

              <div class="data-box">
                <div class="data-code" v-show='item.pay_type==="1"'>
                  <img src="@images/tel.png" alt/>
                  <a
                    @click="goTel($event)"
                    class="phone"
                    :href="'tel://' + item.telephone"
                  >{{item.telephone}}</a>
                </div>

                <div class="data-num">
                  <span>共{{item.product_num}}件</span>
                  <p>实付：</p>
                  <i>￥{{item.total_amount| fixdTwo}}</i>
                </div>
              </div>
            </div>

            <div class="bottom-info">
              <div class="black-btn info-btn">查看详情</div>
              <div
                class="green-btn info-btn"
                @click.stop="handleOrderBtn(item.id, 3)"
                v-if="item.order_status == 2"
              >完成配送
              </div>
              <div
                class="green-btn info-btn"
                @click.stop="handleOrderBtn(item.id, 2)"
                v-if="item.order_status == 1"
              >开始配送
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>

    <div id="index-tabbar">
      <router-link to="/">
        <img src="@images/home-active.png" alt/>
        <span>订单</span>
      </router-link>
      <router-link to="/dataCenter">
        <img src="@images/mine.png" alt/>
        <span>数据中心</span>
      </router-link>
    </div>

    <van-popup v-model="popShow" position="left" style="height:100vh;width:68.53* 3.75px ;">
      <div class="pop-view">
        <div class="pop-top">
          <img v-lazy="shopInfo.market_logo" alt/>
          <div class="shop-info">
            <p>{{shopInfo.market_name}}</p>
            <div class="online">
              <span>营业时间</span>
              <i>{{shopInfo.start_time}} ~ {{shopInfo.end_time}}</i>
            </div>
          </div>
        </div>

        <div class="menu-box">
          <div class="menu-item" @click="$router.push({path: '/serviceList'})">
            <div
              class="num"
              v-if="shopInfo.OrdersAfterSalesCount != 0"
            >{{shopInfo.OrdersAfterSalesCount}}
            </div>
            <img src="@images/refund.png" alt/>
            <p>退款/售后</p>
          </div>
          <div class="menu-item" @click="$router.push({path: '/myWallet'})">
            <img src="@images/wallet.png" alt/>
            <p>钱包</p>
          </div>
          <div class="menu-item" @click="restOrWork(shopInfo.business_status)">
            <img
              :src="shopInfo.business_status === '1' ? require('@images/rest.png') : require('@images/work.png')"
              alt
            />
            <p>{{shopInfo.business_status === '1' ? '歇业休息' : '开业'}}</p>
          </div>
        </div>

        <div class="other-item" @click="$router.push({path: '/shopSet'})">
          <img class="logo" src="@images/shop-set.png" alt/>
          <p>店铺设置</p>
          <img class="arrow" src="@images/arrow-nav.png" alt/>
        </div>
        <div class="other-item" @click="$router.push({path: '/handleMenu'})">
          <img class="logo" src="@images/goods.png" alt/>
          <p>商品管理</p>
          <img class="arrow" src="@images/arrow-nav.png" alt/>
        </div>
        <div class="other-item" @click="$router.push({path: '/management'})">
          <img class="logo" src="@images/sell-icon.png" alt/>
          <p>在售商品</p>
          <img class="arrow" src="@images/arrow-nav.png" alt/>
        </div>

        <div class="other-item" @click="$router.push({path: '/problem'})">
          <img class="logo" src="@images/problem.png" alt/>
          <p>问题反馈</p>
          <img class="arrow" src="@images/arrow-nav.png" alt/>
        </div>

        <div class="other-item" @click="$router.push({path: '/shareShop'})">
          <img class="logo" src="@images/share.png" alt/>
          <p>分享店铺</p>
          <img class="arrow" src="@images/arrow-nav.png" alt/>
        </div>

        <div class="other-item" @click="out">
          <img class="logo" src="@images/login-out.png" alt/>
          <p>退出登录</p>
          <img class="arrow" src="@images/arrow-nav.png" alt/>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
  import {handleOrder, ordersList, shopRest, userInfo} from "../api/requist";
  import {Dialog, Toast} from "vant";

  export default {
    components: {},
    props: [],
    filters: {
      fixdTwo: function (val) {
        return Math.round(val * 100) / 100;
      }
    },
    data() {
      return {
        nothing: false,
        loading: false,
        finished: false,
        refreshing: false,
        shopInfo: {},
        shopLogo: "",
        winUrl: "",
        popShow: false,
        code: "",
        activeIndex: 0,
        orderList: [],
        pageCurrent: 1,
        pageSize: 5,
        choiceItem: ["待配送", "配送中", "已完成", "已取消"],
        swiperOption: {
          slidesPerView: 4,
          slidesPerGroup: 4,
          spaceBetween: 4,
          observer: true, // 修改swiper自己或子元素时，自动初始化swiper
          observeParents: true // 修改swiper的父元素时，自动初始化swiper
        }
      };
    },
    created() {
    },
    mounted() {
      this.getUser();
    },
    watch: {},
    computed: {},
    methods: {

      goTel(e) {
        e.stopPropagation();
      },

      out() {
        localStorage.clear();
        localStorage.removeItem("merch_id");
        sessionStorage.removeItem("firstPage");
        sessionStorage.removeItem("reload");
        this.$router.replace({path: "/authorize"});
      },

      handleOrderBtn(id, type) {
        let title;
        if (type == 2) {
          title = "确认开始配送吗";
        }

        if (type == 3) {
          title = "确认完成配送吗";
        }
        Dialog.confirm({
          title: title,
          message: ""
        })
          .then(() => {
            let formData = new FormData();
            formData.append("merch_id", localStorage.getItem("merch_id"));
            formData.append("orders_id", id);
            formData.append("order_status", type);
            handleOrder(formData).then(res => {
              // 开始配送
              if (res.code === 200) {
                this.orderList.forEach(item => {
                  if (item.id == id) {
                    return (item.order_status = type);
                  }
                });

                if (type === 2) {
                  Toast("开始配送");
                } else if (type === 3) {
                  Toast("完成配送");
                }
              }
            });
          })
          .catch(() => {
            // on cancel
          });
      },
      async restOrWork(type) {
        if (type === "1") {
          this.$router.push({path: "/tackRest"});
        } else {
          let formData = new FormData();
          formData.append("merch_id", localStorage.getItem("merch_id"));
          formData.append("business_status", 1);
          let res = await shopRest(formData);

          if (res.code === 200) {
            Toast("开业成功");
            this.shopInfo.business_status = "1";
          }
        }
      },
      async getList() {
        var order_status;

        if (this.activeIndex === 0) {
          order_status = 1;
        } else if (this.activeIndex === 1) {
          order_status = 2;
        } else if (this.activeIndex === 2) {
          order_status = 3;
        } else if (this.activeIndex === 3) {
          order_status = 7;
        }

        if (this.refreshing) {
          this.pageCurrent = 1;
          this.orderList = [];
          this.refreshing = false;
        }
        let formData = new FormData();
        formData.append("merch_id", localStorage.getItem("merch_id"));
        formData.append("order_status", order_status);
        formData.append("pageCurrent", this.pageCurrent);
        formData.append("pageSize", this.pageSize);
        let res = await ordersList(formData);

        if (res.code === 200) {
          this.orderList.push(...res.data.OrdersList);
          this.shopLogo = res.data.merchInfo.market_logo;

          if (res.data.OrdersList.length != 0) {
            this.pageCurrent = ++this.pageCurrent;
          } else {
            this.finished = true;
          }
          this.loading = false;
        } else {
          this.finished = true;
        }

        if (this.orderList.length == 0) {
          this.nothing = true;
        } else {
          this.nothing = false;
        }
      },

      onRefresh() {
        this.finished = false;
        this.loading = true;
        this.getList();
      },

      async getUser() {
        let formData = new FormData();
        formData.append("merch_id", localStorage.getItem("merch_id"));
        let res = await userInfo(formData);
        if (res.code === 200) {
          this.shopInfo = res.data;
        }
      },

      goDetail(id) {
        this.$router.push({
          path: "/listDetail",
          query: {
            id
          }
        });
      },

      changeIndex(index) {
        this.activeIndex = index;
        this.pageCurrent = 1;
        this.orderList = [];
        this.finished = false;
        this.getList();
      }
    }
  };
</script>
<style lang="scss">
  @import "../common/css/theme.scss";

  #listIndex {
    padding-bottom: 65px;
    box-sizing: border-box;
    min-height: 100vh;
    background: rgba(245, 247, 250, 1);

    .van-pull-refresh {
      min-height: 100vh;
      padding-top: 9.6 * 3.75px;
      box-sizing: border-box;
    }

    .nothing {
      height: 66.18 * 3.75px;
      width: 100%;
      margin-top: 48.53 * 3.75px;
    }

    .nothing-text {
      color: rgba(96, 98, 102, 1);
      font-size: 3.73 * 3.75px;
      text-align: center;
      margin-top: 3.47 * 3.75px;
    }

    .pop-view {
      height: 100%;
      width: 100%;
      padding: 6.13 * 3.75px 4.27 * 3.75px 0;
      box-sizing: border-box;

      .pop-top {
        display: flex;
        align-items: center;

        img {
          object-fit: cover;
          border-radius: 50%;
          height: 15.47 * 3.75px;
          width: 15.47 * 3.75px;
          margin-right: 1.87 * 3.75px;
        }

        .shop-info {
          p {
            margin-bottom: 1.07 * 3.75px;
            text-align: left;
            color: rgba(96, 98, 102, 1);
            font-weight: bold;
            font-size: 3.73 * 3.75px;
          }

          .online {
            display: flex;
            align-items: center;

            span {
              color: rgba(96, 98, 102, 1);
              font-size: 3.2 * 3.75px;
              margin-right: 1.07 * 3.75px;
            }

            i {
              font-style: normal;
              font-size: 3.2 * 3.75px;
              color: rgba(96, 98, 102, 1);
            }
          }
        }
      }

      .menu-box {
        margin-top: 9.6 * 3.75px;
        margin-bottom: 13.6 * 3.75px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .menu-item {
          position: relative;

          .num {
            position: absolute;
            right: 2.4 * 3.75px;
            top: 0;
            width: 6.4 * 3.75px;
            height: 6.4 * 3.75px;
            background: $tab-color;
            border-radius: 50%;
            opacity: 1;
            font-size: 4.27 * 3.75px;
            font-weight: bold;
            text-align: center;
            line-height: 6.4 * 3.75px;
            color: rgba(255, 255, 255, 1);
          }
        }

        img {
          height: 12.8 * 3.75px;
          width: 12.8 * 3.75px;
          margin-bottom: 2.4 * 3.75px;
        }

        p {
          color: #303133;
          font-weight: bold;
          font-size: 4.27 * 3.75px;
        }
      }

      .other-item {
        margin-bottom: 3.73 * 3.75px;
        display: flex;
        align-items: center;

        .logo {
          height: 7.47 * 3.75px;
          width: 7.47 * 3.75px;
          margin-right: 2.13 * 3.75px;
        }

        p {
          color: rgba(48, 49, 51, 1);
          font-weight: bold;
          font-size: 4.27 * 3.75px;
          margin-right: 28.73 * 3.75px;
        }

        .arrow {
          width: 3.73 * 3.75px;
          height: 3.73 * 3.75px;
        }
      }
    }

    nav {
      padding: 0 2.67 * 3.75px;
      box-sizing: border-box;
      width: 100%;
      height: 12.7 * 3.75px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: rgba(240, 242, 245, 1);

      img {
        height: 4.68 * 3.75px;
        width: 2.68 * 3.75px;
      }

      span {
        font-size: 4.93 * 3.75px;
        color: rgba(0, 0, 0, 1);
      }
    }

    .choice-box {
      z-index: 9;
      position: fixed;
      top: 0;
      left: 0;
      background-color: #fff;
      display: flex;
      width: 100%;
      height: 9.6 * 3.75px;
      align-items: center;

      .tabbar {
        padding-left: 1.87 * 3.75px;
        box-sizing: border-box;
        width: 23.2 * 3.75px;
        display: flex;
        align-items: center;

        .logo {
          height: 7.73 * 3.75px;
          width: 7.73 * 3.75px;
          border-radius: 50%;
          margin-right: 1.33 * 3.75px;
          object-fit: cover;
        }

        .btn {
          height: 3.47 * 3.75px;
          width: 5.6 * 3.75px;
        }
      }

      .status-box {
        height: 100%;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .status-item {
          line-height: 9.6 * 3.75px;
          height: 100%;
          width: 18.4 * 3.75px;
          font-size: 3.73 * 3.75px;
          box-sizing: border-box;
          color: rgba(48, 49, 51, 1);

          &.active {
            border-bottom: 2px solid $theme-color;
          }
        }
      }
    }

    .list-box {

      .list-item {
        position: relative;
        padding: 0 2.13 * 3.75px;
        box-sizing: border-box;
        margin: 10px 12px 0;
        height: 56 * 3.75px;
        background: rgba(255, 255, 255, 1);
        opacity: 1;
        border-radius: 1 * 3.75px;

        .status-pic {
          position: absolute;
          height: 13.33 * 3.75px;
          width: 13.33 * 3.75px;
          right: 2.13 * 3.75px;
          top: 1.07 * 3.75px;
        }

        .top-info {
          display: flex;
          align-items: center;
          height: 10.93 * 3.75px;
          justify-content: space-between;
          border-bottom: 1px solid rgba(240, 242, 245, 1);

          .top-inner {
            display: flex;
            align-items: center;
          }

          .info-name {
            margin-right: 2.13 * 3.75px;
          }

          span {
            color: #303133;
            font-size: 3.2 * 3.75px;
          }

          p {
            color: $theme-color;
            font-size: 3.2 * 3.75px;
          }
        }

        .center-info {
          padding-top: 3.2 * 3.75px;
          box-sizing: border-box;
          border-bottom: 1px solid rgba(240, 242, 245, 1);
          height: 32.27 * 3.75px;

          .myswiper {
            margin-bottom: 2.13 * 3.75px;
            height: 17.6 * 3.75px;

            .swiper-slide {
              height: 17.6 * 3.75px;
              width: 17.6 * 3.75px;

              img {
                height: 17.6 * 3.75px;
                width: 17.6 * 3.75px;
              }
            }
          }

          .data-box {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .data-code {
              flex: 0 29.07 * 3.75px;
              width: 29.07 * 3.75px;
              height: 7.73 * 3.75px;
              background: rgba(245, 247, 250, 1);
              border-radius: 4 * 3.75px;
              display: flex;
              align-items: center;
              padding: 0 1.87 * 3.75px;
              box-sizing: border-box;

              img {
                width: 3.2 * 3.75px;
                height: 3.2 * 3.75px;
                margin-right: 1.07 * 3.75px;
              }

              a {
                color: rgba(0, 0, 0, 1);
                font-size: 3.2 * 3.75px;
              }
            }

            .data-num {
              margin-right: 1.34 * 3.75px;
              display: flex;
              flex: 1;
              justify-content: flex-end;
              align-items: center;

              span {
                color: rgba(48, 49, 51, 1);
                font-size: 3.2 * 3.75px;
                margin-right: 1.07 * 3.75px;
              }

              p {
                color: rgba(48, 49, 51, 1);
                font-size: 3.2 * 3.75px;
              }

              i {
                font-style: normal;
                font-weight: bold;
                color: rgba(255, 0, 0, 1);
                font-size: 4.27 * 3.75px;
              }
            }
          }
        }

        .bottom-info {
          height: 12.8 * 3.75px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding: 0 0.8 * 3.75px;
          box-sizing: border-box;

          .info-btn {
            background: rgba(255, 255, 255, 1);
            padding: 4px 0;
            width: 19.73 * 3.75px;
            border-radius: 1 * 3.75px;
            font-size: 3.73 * 3.75px;

            &:last-child {
              margin-left: 3.2 * 3.75px;
            }
          }

          .green-btn {
            border: 1px solid $theme-color;
            color: $theme-color;
          }

          .black-btn {
            border: 1px solid rgba(96, 98, 102, 1);
            color: #606266;
          }
        }
      }
    }
  }
</style>
